import '../styles/globals.css';
import { Provider } from 'react-redux';
import { store } from '../app/store';
import type { AppProps } from 'next/app';
import * as gtag from '../lib/gtag'
import * as fbq from '../lib/fbq'
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Locales } from '../constants/appConstants/Locales';
import { NewRelicSnippet } from '../../newrelic/NewRelicSnippet';
import { getPublicAssetsURL } from '../utils/getURLs';
import { GoogleAnalyticsID } from '../constants/appConstants/GoogleAnalyticsID';
import { GoogleAnalytics4ID } from '../constants/appConstants/GoogleAnalytics4ID';
import { FaceBookPixelID } from '../constants/appConstants/FaceBookPixelID';
import { isDomainEligibleForAnalytics } from "../utils/validationUtil";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { locale } = useRouter();

  //For Google analytics
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      gtag.pageview(url, getGoogleAnalyticsID(locale))
      gtag.pageview(url, getGoogleAnalytics4ID(locale))
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const getGoogleAnalyticsID = (localeData: any) => {
    switch (localeData) {
      case Locales.se:
        return GoogleAnalyticsID.se;

      case Locales.ads_da:
        return GoogleAnalyticsID.da;

      case Locales.no:
        return GoogleAnalyticsID.no;

      default:
        return GoogleAnalyticsID.dk;
    }
  }

  const getGoogleAnalytics4ID = (localeData: any) => {
    switch (localeData) {
      case Locales.se:
        return GoogleAnalytics4ID.se;

      case Locales.ads_da:
        return GoogleAnalytics4ID.da;

      case Locales.no:
        return GoogleAnalytics4ID.no;

      default:
        return GoogleAnalytics4ID.dk;
    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      fbq.pageview();
    }
    // the below will only fire on route changes (not initial load - that is handled in the script below)
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const [isAnalyticsRequired, setIsAnalyticsRequired] = useState<boolean>(false);
  useEffect(() => {
      if (typeof window !== 'undefined')
          setIsAnalyticsRequired(isDomainEligibleForAnalytics(window.location.hostname));
  }, [])

  const getFaceBookPixelID = (localeData: any) => {
    switch (localeData) {
      case Locales.se:
        return FaceBookPixelID.se;

      case Locales.ads_da:
        return FaceBookPixelID.da;

      case Locales.no:
        return FaceBookPixelID.no;

      default:
        return FaceBookPixelID.dk;
    }
  }

  return (
    <>
      {locale === Locales.dk && <Head>
        <title>Udlej dit sommerhus | Tjen op til 47.900 kr. skattefrit | DanCenter</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="Lad DanCenter stå for en sikker og indbringende udlejning af dit sommerhus. Læs om gældende regler for lejeindtægt, skat og fradrag ved udlejning af sommerhus." />

        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="129" />
        <meta property="og:image:height" content="53" />
        <link rel="apple-touch-icon" sizes="57x57" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-57x57.png")} />
        <link rel="apple-touch-icon" sizes="60x60" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-60x60.png")} />
        <link rel="apple-touch-icon" sizes="72x72" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-72x72.png")} />
        <link rel="apple-touch-icon" sizes="76x76" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-76x76.png")} />
        <link rel="apple-touch-icon" sizes="114x114" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-114x114.png")} />
        <link rel="apple-touch-icon" sizes="120x120" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-120x120.png")} />
        <link rel="apple-touch-icon" sizes="144x144" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-144x144.png")} />
        <link rel="apple-touch-icon" sizes="152x152" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-152x152.png")} />
        <link rel="apple-touch-icon" sizes="180x180" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/apple-icon-180x180.png")} />
        <link rel="icon" type="image/png" sizes="192x192" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/android-icon-192x192.png")} />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/favicon-32x32.png")} />
        <link rel="icon" type="image/png" sizes="96x96" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/favicon-96x96.png")} />
        <link rel="icon" type="image/png" sizes="16x16" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/favicon-16x16.png")} />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwdk/favicon.ico")} />
        <NewRelicSnippet />
      </Head>}

      {locale === Locales.se && <Head>
        <title>Hyr ut din stuga  | Stugsommar</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="Bästa sättet att hyra ut din stuga och få en bra inkomst. Du får maximalt med bokningar, samtidigt som du kan tjäna upptill 50 000:- skattefritt genom att hyra ut din stuga via oss på Stugsommar. " />

        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="129" />
        <meta property="og:image:height" content="53" />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwse/favicon.ico")} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <NewRelicSnippet />
      </Head>}

      {locale === Locales.no && <Head>
        <title>Leie ut ferieboligen din | DanCenter</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="Leie ut feriehuset ditt? DanCenter er Nordens mest erfarne formidler av ferieboliger og hytter. Vi setter pris på huset ditt og skaper resultater med bred markedsføring, rådgivning, tillit, trygghet, sikker betaling, forsikring og mye mer." />

        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="129" />
        <meta property="og:image:height" content="53" />
        <link rel="apple-touch-icon" sizes="57x57" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-57x57.png")} />
        <link rel="apple-touch-icon" sizes="60x60" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-60x60.png")} />
        <link rel="apple-touch-icon" sizes="72x72" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-72x72.png")} />
        <link rel="apple-touch-icon" sizes="76x76" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-76x76.png")} />
        <link rel="apple-touch-icon" sizes="114x114" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-114x114.png")} />
        <link rel="apple-touch-icon" sizes="120x120" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-120x120.png")} />
        <link rel="apple-touch-icon" sizes="144x144" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-144x144.png")} />
        <link rel="apple-touch-icon" sizes="152x152" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-152x152.png")} />
        <link rel="apple-touch-icon" sizes="180x180" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/apple-icon-180x180.png")} />
        <link rel="icon" type="image/png" sizes="192x192" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/android-icon-192x192.png")} />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/favicon-32x32.png")} />
        <link rel="icon" type="image/png" sizes="96x96" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/favicon-96x96.png")} />
        <link rel="icon" type="image/png" sizes="16x16" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/favicon-16x16.png")} />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/favicon.ico")} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={getPublicAssetsURL("/img/lead/dcleads/favicons/wwwno/ms-icon-144x144.png")} />
        <meta name="theme-color" content="#ffffff" />
        <NewRelicSnippet />
      </Head>}

      {locale === Locales.ads_da && <Head>
        <title>Søg Admiral Strand Feriehuse</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />

        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="129" />
        <meta property="og:image:height" content="53" />
        <link rel="icon" type="image/png" sizes="32x32" href={getPublicAssetsURL("/img/lead/ads/favicon.ico")} />
        <meta name="theme-color" content="#ffffff" />
        <NewRelicSnippet />
      </Head>}

      {/* google analytics */}
      {isAnalyticsRequired &&
        <>
          <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${getGoogleAnalyticsID(locale)}`} />
          <Script id='initial-google-analytics' strategy="lazyOnload">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${getGoogleAnalyticsID(locale)}', {
              page_path: window.location.pathname,
            });
        `}
          </Script>
          <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${getGoogleAnalytics4ID(locale)}`} />
          <Script id='initial-google-analytics-4' strategy="lazyOnload">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${getGoogleAnalytics4ID(locale)}', {
              page_path: window.location.pathname,
            });
        `}
          </Script>
        </>}

      {/* facebook pixel */}
      {process.env.NEXT_PUBLIC_SHOW_ANALYTICS === 'true' &&
        <Script id="facebook-pixel">
          {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${getFaceBookPixelID(locale)});
        fbq('track', 'PageView');
      `}
        </Script>
      }
      <div className={locale === Locales.ads_da ? 'ads_style_all' : 'style_all'}>
        <Provider store={store} >
          <Component {...pageProps} />
          <ToastContainer bodyClassName={'toastBody'} />
        </Provider>
      </div>
    </>
  );
}

export default MyApp
