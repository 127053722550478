import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

// declaring the types for our state
export type AccoDetailsState = {
    fullName: string;
    email: string;
    privatePool: boolean;
    accoId: string;
    ownerId: string;
    estimation: number;
    bedrooms:string;
};

const initialState: AccoDetailsState = {
    fullName: '',
    email: '',
    privatePool: false,
    accoId: '',
    ownerId: '',
    estimation: 0,
    bedrooms:'',
};

export const accoDetailsSlice = createSlice({
    name: 'accoDetails',
    initialState,

    reducers: {
        assignFullName: (state, action: PayloadAction<string>) => {
            state.fullName = action.payload;
        },
        assignEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        assignPrivatePool: (state, action: PayloadAction<boolean>) => {
            state.privatePool = action.payload;
        },
        assignAccoId: (state, action: PayloadAction<string>) => {
            state.accoId = action.payload;
        },
        assignOwnerId: (state, action: PayloadAction<string>) => {
            state.ownerId = action.payload;
        },
        assignEstimation: (state, action: PayloadAction<number>) => {
            state.estimation = action.payload;
        },
        assignBedrooms: (state, action: PayloadAction<string>) => {
            state.bedrooms = action.payload;
        },
    },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
    assignFullName,
    assignEmail,
    assignPrivatePool,
    assignAccoId, assignOwnerId,
    assignEstimation,assignBedrooms
} = accoDetailsSlice.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selectFullName = (state: RootState) => state.accoDetails.fullName;
export const selectEmail = (state: RootState) => state.accoDetails.email;
export const selectPrivatePool = (state: RootState) => state.accoDetails.privatePool;
export const selectAccoId = (state: RootState) => state.accoDetails.accoId;
export const selectOwnerId = (state: RootState) => state.accoDetails.ownerId;
export const selectEstimation = (state: RootState) => state.accoDetails.estimation;
export const selectBedrooms = (state: RootState) => state.accoDetails.bedrooms;

// exporting the reducer here, as we need to add this to the store
export default accoDetailsSlice.reducer;