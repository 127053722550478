export const Language = {
    "dk": 'da',
    "se": 'sv',
    "no": 'no',
    "ads_da": 'da'
}

export const Locales = {
    "dk": 'da-DK',
    "se": 'sv',
    "no": 'nn-NO',
    "ads_da": 'da'
}
export const LocaleVariables = {
    "dk": 'dk',
    "se": 'se',
    "no": 'no',
    "ads_da": 'da'
}

export const LocaleKeyWord = {
    "dk": 'DCDK',
    "se": 'SSSE',
    "no": 'DCNO',
    "ads_da": 'ASDK'
}