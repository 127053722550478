declare const window: any;

// log the pageview with their URL
export const pageview = (url: any, googleAnalyticsID: any) => {
    if (typeof window.gtag !== 'undefined') {
        window.gtag('config', googleAnalyticsID, {
            page_path: url,
        })
    }
}

// log specific events happening.
export const event = ({ category, action, label }: { category: string, action: string, label: string }) => {

    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', action, {
            'event_category': category,
            'event_label': label
        })
    }
}