declare const window: any;

// log the pageview with their URL
export const pageview = () => {
    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'PageView')
    }
}

// log specific events happening.
export const event = ({ eventName, category, action, label }: { eventName: string, category: string, action: string, label: string }) => {

    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', eventName, {
            'content_category': category,
            'content_action': action,
            'content_label': label
        })
    }
}