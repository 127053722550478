const returnImageURL = (dataForImage: any, screenWidth?: number) => {
    let imageURL = '';
    if (dataForImage?.ext === '.svg') {
        imageURL = dataForImage?.url;
    }
    else {
        if (screenWidth != undefined) {
            let defaultURL = dataForImage?.url;
            if (screenWidth >= 1200) {
                imageURL = dataForImage?.formats?.xlarge?.url || defaultURL;
            }
            else if (screenWidth >= 992 && screenWidth < 1200) {
                imageURL = dataForImage?.formats?.large?.url || defaultURL;
            }
            else if (screenWidth >= 768 && screenWidth < 992) {
                imageURL = dataForImage?.formats?.medium?.url || defaultURL;
            }
            // To be handled with proper  thumbnail width
            else if (screenWidth < 100) {
                imageURL = dataForImage?.formats?.thumbnail?.url || defaultURL;
            }
            else {
                imageURL = dataForImage?.formats?.small?.url || defaultURL;
            }
        }
    }
    return imageURL;
}

const getPushURL = (hostName: string, pushPath: string) => {
    let pushURL = pushPath;
    if (hostName.includes('dancenter.dk')) {
        pushURL = '/udlejning-sommerhus' + pushPath;
        return pushURL;
    }
    else if (hostName.includes('admiralstrand.dk')) {
        pushURL = '/udlejning-sommerhus' + pushPath;
        return pushURL;
    }
    else if (hostName.includes('dancenter.no')) {
        pushURL = '/utleie-av-hytte' + pushPath;
        return pushURL;
    }
    else if (hostName.includes('stugsommar.se')) {
        pushURL = '/hyra-ut-stuga' + pushPath;
        return pushURL;
    }
    else {
        return pushURL;
    }
}

const getPublicAssetsURL = (pushPath: string) => {
    let publicAssetsURL;
    publicAssetsURL = '/ho-leadgen' + pushPath;
    return publicAssetsURL;
}
export { returnImageURL, getPushURL, getPublicAssetsURL };
