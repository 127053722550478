import { LocaleVariables } from "../constants/appConstants/Locales";

const getNumericValidation = (val: string) => {
    let num = val;
    let len = val.length;

    for (let i = 0; i < len; i++) {
        let ch = num[i];
        if (ch >= '0' && ch <= '9') continue;
        return false;
    }

    return true;

}

const getAlphaNumericValidation = (val: string) => {
    let alphaNum = val;
    let len = val.length;

    for (let i = 0; i < len; i++) {
        let ch = alphaNum[i];
        if ((ch >= '0' && ch <= '9') || (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z')) continue;
        return false;
    }
    return true;
}

const getAlphaValidation = (val: string) => {
    let alpha = val;
    let len = val.length;
    let counter = 0;

    for (let i = 0; i < len; i++) {
        let ch = alpha[i];
        if ((ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z') || (ch >= 'À' && ch <= 'Ö') || (ch >= 'Ø' && ch <= 'ö') || (ch >= 'ø' && ch <= 'ÿ')) continue;
        else if (ch === ' ') {
            counter++;
            if (counter >= len) return false;
            else continue;
        }
        return false;
    }
    return true;
}
const getSpaceValidation = (val: string) => {
    let alpha = val;
    let len = val.length;
    let counter = 0;

    for (let i = 0; i < len; i++) {
        let ch = alpha[i];
        if (ch === ' ') {
            counter++;
            if (counter >= len) return false;
        }
    }
    return true;
}

const handleEmailValidation = (mail: string) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailformat)) return true;
    else return false;
}

const handlePhoneNumberValidation = (phoneNumber: string, phoneNumberFormat: string) => {
    if (phoneNumber.length === phoneNumberFormat.length) return true;
    else return false;
}

const isHeaderFooterRequired = (pathName: string, locale?: string) => {
    if (locale === LocaleVariables.no) {
        return pathName === '/ho-leadgen' || pathName === '/ho-leadgen/' || pathName === '' || pathName === '/' || pathName === '/step-2/' || pathName === '/utleie-av-hytte' || pathName === '/utleie-av-hytte/' || pathName === '/utleie-av-hytte/step-2/';
    }
    else if (locale === LocaleVariables.se) {
        return pathName === '/ho-leadgen' || pathName === '/ho-leadgen/' || pathName === '' || pathName === '/' || pathName === '/step-2/' || pathName === '/hyra-ut-stuga' || pathName === '/hyra-ut-stuga/' || pathName === '/hyra-ut-stuga/step-2/';
    }
    //For ads and dk locale
    else {
        return pathName === '/ho-leadgen' || pathName === '/ho-leadgen/' || pathName === '' || pathName === '/' || pathName === '/step-2/' || pathName === '/udlejning-sommerhus' || pathName === '/udlejning-sommerhus/' || pathName === '/udlejning-sommerhus/step-2/';
    }
}

const isDomainEligibleForAnalytics = (hostName: string) => {
    return (!hostName.includes('oyorooms.com') && process.env.NEXT_PUBLIC_SHOW_ANALYTICS === 'true')
}


export { getNumericValidation, getAlphaNumericValidation, getAlphaValidation, getSpaceValidation, handleEmailValidation, handlePhoneNumberValidation, isHeaderFooterRequired, isDomainEligibleForAnalytics };
